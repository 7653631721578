/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
	position: relative;
}

.flickity-enabled:focus {
	outline: none;
}

.flickity-viewport {
	overflow: hidden;
	position: relative;
	height: 100%;
}

.flickity-slider {
	position: absolute;
	width: 100%;
	height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
	-webkit-tap-highlight-color: transparent;
	tap-highlight-color: transparent;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
	cursor: move;
	cursor: -webkit-grab;
	cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
	cursor: -webkit-grabbing;
	cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
	position: absolute;
	background: hsla(0, 0%, 100%, 0.75);
	border: none;
	color: #333;
}

.flickity-button:hover {
	background: white;
	cursor: pointer;
}

.flickity-button:focus {
	outline: none;
	box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
	opacity: 0.6;
}

.flickity-button:disabled {
	opacity: 0.3;
	cursor: auto;
	/* prevent disabled button from capturing pointer up event. #716 */
	pointer-events: none;
}

.flickity-button-icon {
	fill: #333;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
	top: 50%;
	width: 44px;
	height: 44px;
	border-radius: 50%;
	/* vertically center */
	transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
	left: 10px;
}
.flickity-prev-next-button.next {
	right: 10px;
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
	left: auto;
	right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
	right: auto;
	left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
	position: absolute;
	left: 20%;
	top: 20%;
	width: 60%;
	height: 60%;
}

/* ---- page dots ---- */

.flickity-page-dots {
	position: absolute;
	width: 100%;
	bottom: -25px;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;
	line-height: 1;
}

.flickity-rtl .flickity-page-dots {
	direction: rtl;
}

.flickity-page-dots .dot {
	display: inline-block;
	width: 10px;
	height: 10px;
	margin: 0 8px;
	background: #333;
	border-radius: 50%;
	opacity: 0.25;
	cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
	opacity: 1;
}

/* ---- ED variants ---- */

/* Demo */

.flickity-demo img {
	height: 220px;
	border-radius: 10px;
	margin-right: 20px;
}


/* Horizontal */

.flickity-horizontal .flickity-prev-next-button .flickity-button-icon {
	position: absolute;
	left: 20%;
	top: 20%;
	width: 25px;
}


/* Gallery */

@media only screen and (max-width: 768px) {
	.flickity-gallery {
		height: 100% !important;
	}

	.flickity-gallery .flickity-prev-next-button {
		display: none;
	}
}

.flickity-gallery {
	position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    /* height: 100%; */
    height: calc(100% - 120px);
}

.flickity-gallery .flickity-viewport {
    height: 100% !important;
}

.flickity-gallery .flickity-prev-next-button {
	width: 60px;
	height: 60px;
	transform: translateY(-50%) rotate(-180deg);
	overflow: hidden;
	outline: none;
	box-shadow: none;
	background-color: transparent;
}

.flickity-gallery .flickity-prev-next-button.previous {
	left: 30px;
}

.flickity-gallery .flickity-prev-next-button.next {
	right: 30px;
}

.flickity-gallery .flickity-prev-next-button .flickity-button-icon {
	width: 150px;
	height: 150px;
	fill: white;
}

.flickity-gallery .flickity-prev-next-button.previous .flickity-button-icon {
	top: 8px;
	left: 0px;
}

.flickity-gallery .flickity-prev-next-button.next .flickity-button-icon {
	top: -98px;
	left: -90px;
}


/* Nav */

@media only screen and (max-width: 768px) {
	.flickity-nav {
		opacity: 0;
		pointer-events: none;
	}
}

.flickity-nav {
	width: 100%;
}

.flickity-nav__cell {
	transition: opacity 0.5s;
	opacity: 1;
}

.flickity-nav__cell:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
	border: solid 3px transparent;
	transition: 0.3s;
}

/* .flickity-nav__cell.is-selected:after {
    border: solid 3px green;
} */

.flickity-nav__cell.active-index:after {
    border: solid 3px white;
}

.flickity-nav__cell.initial-style {
    opacity: 0;
}


/* Categories */

.flickity-categories .flickity-prev-next-button {
	width: 35px;
	height: 35px;
	/* overflow: hidden; */
	outline: none;
	/* box-shadow: 0px 3px 10px rgba(36, 6, 48, 0.2); */
	box-shadow: none;
	background-color: transparent;
	opacity: 1;
}

.flickity-categories .flickity-button:disabled {
	opacity: 0;
	cursor: auto;
	pointer-events: none;
}

/* .flickity-categories .flickity-prev-next-button:hover {
	box-shadow: 0px 3px 10px rgba(36, 6, 48, 0.4);
} */

/* .flickity-categories .flickity-prev-next-button .flickity-button-icon {
	left: 30%;
    top: 30%;
    width: 40%;
    height: 40%;
} */

.flickity-categories .flickity-prev-next-button .flickity-button-icon {
	z-index: 1;
	left: 0%;
    top: 0%;
    width: 35px;
    height: 35px;
    box-shadow: 0px 3px 10px rgb(36 6 48 / 20%);
    padding: 10px;
    border-radius: 50%;
	background-color: white;
}

.flickity-categories .flickity-prev-next-button .flickity-button-icon:hover {
	box-shadow: 0px 3px 10px rgba(36, 6, 48, 0.4);
}

.flickity-categories__cell.active-index {
	background-color: #E3E0FB !important;
}

.flickity-categories .flickity-prev-next-button:after {
	content: '';
	z-index: 0;
	position: absolute;
	top: -12px;
	width: 180px;
	height: 60px;
	pointer-events: none;
	background: rgba(0,0,0,0.3);
	background: linear-gradient(270deg, #FFFFFF 33.46%, rgba(255, 255, 255, 0.505155) 64.8%, rgba(255, 255, 255, 0) 95.56%);
}

.flickity-categories .flickity-prev-next-button.previous:after {
	left: -10px;
	transform: rotate(-180deg);
}

.flickity-categories .flickity-prev-next-button.next:after {
	right: -10px;
}

@media only screen and (max-width: 992px) {
	.flickity-categories .flickity-prev-next-button:after {
		width: 80px;
	}
}