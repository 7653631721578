.swiper-landing-pagination {
  position: absolute;
  z-index: 2;
  background: #E81E42 !important;
  width: 88px !important;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50% 50% 0;
  bottom: auto !important;
  top: 22%;
  left: -44px !important;
}
@media only screen and (min-width: 1400px) {
	.swiper-landing-pagination {
    width: 108px !important;
    height: 108px;
    left: -54px !important;
  }
}
.swiper-landing-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  opacity: 1;
}

.swiper-pagination-clickable {
  width: 200px;
  background-color: transparent;
}
/* FAB9BD */
.swiper-pagination-bullet-active {
  background-color: #fab9bd !important;
}

.swiper-pagination-horizontal > {
  width: 200px;
  background-color: #e3e0fb;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  background-color: #e3e0fb;
}

/* selektor za pagination box
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
 
} */
