.animationPulseParent {
	--pulseDuration: 1.2s;
	--pulseTiming: ease-in-out;
}

.animationPulseParent:hover .animationPulseIcon {
	animation: animationPulseForIcon var(--pulseDuration) var(--pulseTiming);
}

.animationPulseParent:hover .animationPulseDot {
	animation: animationPulseForDot var(--pulseDuration) var(--pulseTiming);
}

.animationPulseIcon {
	position: absolute;
	z-index: 1;
}

.animationPulseDot {
	fill:#E3E0FB;
	opacity: 0;
}

@keyframes animationPulseForIcon {
	0%   { transform: scale(1); }
	50%   { transform: scale(1.2); }
	100%   { transform: scale(1); }
}

@keyframes animationPulseForDot {
	0%   {
		fill: #E3E0FB;
		opacity: 0;
	}
  	50%  {
		fill: #5649B7;
		opacity: 1;
	}
  	100% {
		fill: #E3E0FB;
		opacity: 0;
	}
}

.position + .position, .position + .position .animationPulseDot {
	animation-delay: calc(1 * var(--pulseDuration)) !important;
}

.position + .position + .position, .position + .position + .position .animationPulseDot {
	animation-delay: calc(2 * var(--pulseDuration)) !important;
}

.position0 {
	top: 21px;
	left: 20px;
}

.position1 {
	top: 21px;
	left: 69px;
}

.position2 {
	top: 46px;
	left: 44px;
}

.position3 {
	top: 70px;
	left: 20px;
}

.position4 {
	top: 21px;
	right: 20px;
}

.position5 {
	top: 21px;
	right: 69px;
}

.position6 {
	top: 46px;
	right: 44px;
}

.position7 {
	top: 70px;
	right: 20px;
}