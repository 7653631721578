.passwordBoxTriangle {
  position: absolute;
  background-color: #2D2D2D;
  bottom: -5px;
  top: 100%;
  margin-top: -5px;
  left: 50%;
  margin-left: -5px;
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
}
